import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import Heading from "../components/common/Heading";
import Paragraph from "../components/common/Paragraph";
import Button from "../components/common/Button";
import Shape from "../components/common/Shape";
import CustomHead from "../components/common/Head";

const NotFoundPage = () => (
  <Layout>
    <Section title="404">
      <Heading className="mb-4">
        Sorry, we couldn&apos;t find this page.
      </Heading>
      <Paragraph className="mb-10">
        Please head back to the homepage and try again.
      </Paragraph>
      <Button size="lg" className="mb-10">
        <Link to="/">Back to the homepage</Link>
      </Button>
      <div className="w-1/3 ml-auto">
        <Shape type="bigD" className="text-eucalyptus -rotate-90" />
      </div>
    </Section>
  </Layout>
);

export const Head = () => {
  const pageMeta = {
    title: "404 - Page not found",
    description:
      "Sorry we couldn't find this page. Please return to the homepage",
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default NotFoundPage;
